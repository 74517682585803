<template>
    <div class="container" v-cloak>
        <b-overlay :show="loading" rounded="sm" no-wrap z-index="1000">
            <template v-slot:overlay v-if="paidSuccessfully">
                <div class="text-center">
                    <i class="icon icon-check"></i>
                    <h3>Thank you!</h3>
                </div>
            </template>
        </b-overlay>
        <div class="row">
            <div class="col-12 p-3">
                <button class="btn btn-common btn-back" @click="back">
                    Back
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <h5 class="font-weight-bold">
                            You are going to extend validity period of AutoTouch
                            licenses:
                        </h5>

                        <div class="d-flex flex-column py-2">
                            <h6 class="field-title">Licenses:</h6>
                            <div v-if="!next">
                                <div
                                    v-for="item in licenseKeys"
                                    :key="item"
                                    class="license-key"
                                >
                                    {{ item }}
                                </div>
                            </div>
                            <div
                                v-if="order"
                                class="d-flex flex-row align-content-start flex-wrap"
                                style="gap: 10px;"
                            >
                                <div
                                    class="license-card"
                                    v-for="item in order.license_keys"
                                    :key="item.id"
                                    :class="{
                                        'ios-pro': item.plan === 'IOS_PRO',
                                        'ios-basic': item.plan === 'IOS_BASIC',
                                        'macos-basic':
                                            item.plan === 'MACOS_BASIC',
                                        'macos-pro': item.plan === 'MACOS_PRO',
                                        default: ![
                                            'IOS_BASIC',
                                            'IOS_PRO',
                                            'MACOS_BASIC',
                                            'MACOS_PRO'
                                        ].includes(item.plan)
                                    }"
                                >
                                    <div>License:</div>
                                    <div class="license-key">
                                        {{ item.license_key }}
                                    </div>
                                    <div>Plan:</div>
                                    <div>
                                        {{ item.plan }}
                                    </div>
                                    <div>Old Validity:</div>
                                    <div>
                                        {{ item.old_validity }} Months
                                    </div>
                                    <div>New Validity:</div>
                                    <div>
                                        {{ item.new_validity }} Months
                                    </div>
                                    <div>Current expires:</div>
                                    <div>
                                        {{ item.expire_time ? timeFormat(item.expire_time) : 'N/A' }}
                                    </div>
                                    <div>Extend to time:</div>
                                    <div>
                                        {{ item.extend_to_time ? timeFormat(item.extend_to_time) : 'N/A' }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-column py-2">
                            <h6 class="field-title">Period to Extend:</h6>
                            <div>
                                <b-form-spinbutton
                                    min="1"
                                    max="100"
                                    v-model="yearsToExtend"
                                    size="sm"
                                    inline
                                    :disabled="next"
                                ></b-form-spinbutton>
                                <label class="ml-2">Year(s)</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="!next">
                    <div class="col-12">
                        <button
                            class="btn btn-common"
                            @click="createOrder"
                            :disabled="!licenseKeys || licenseKeys.length <= 0"
                        >
                        Confirm
                        </button>
                    </div>
                </div>
                <div class="row" v-if="order">
                    <div class="col-12 py-2">
                        <h6 class="field-title">Total Amount:</h6>
                        <div class="font-weight-bold">
                            <span class="mr-2" style="font-size: 30px"
                                >$ {{ order.amount / 100 }}</span
                            >
                            <span>USD</span>
                        </div>
                    </div>

                    <div class="col-12 p-3">
                        <div class="mt-3 d-flex justify-content-center w-100">
                            <form
                                v-if="
                                    globalSettings &&
                                        globalSettings.paypal_payee_email_1
                                "
                                id="form_pay"
                                name="form_pay"
                                :action="config.paypal.pdt"
                                method="post"
                            >
                                <div style="display: none">
                                    <input
                                        type="hidden"
                                        name="cmd"
                                        value="_xclick"
                                    />
                                    <input
                                        type="hidden"
                                        name="business"
                                        :value="
                                            globalSettings.paypal_payee_email_1
                                        "
                                    />
                                    <input
                                        type="hidden"
                                        name="item_name"
                                        :value="
                                            `AutoTouch licenses period of validity renew.`
                                        "
                                    />
                                    <input
                                        type="hidden"
                                        name="item_number"
                                        :value="1"
                                    />
                                    <input
                                        type="hidden"
                                        name="amount"
                                        :value="order.amount / 100"
                                    />
                                    <input
                                        type="hidden"
                                        name="currency_code"
                                        value="USD"
                                    />
                                    <input
                                        type="hidden"
                                        name="return"
                                        :value="config.paypal.returnUrl"
                                    />
                                    <input
                                        type="hidden"
                                        name="notify_url"
                                        :value="config.paypal.notifyUrl"
                                    />
                                    <input
                                        type="hidden"
                                        name="invoice"
                                        :value="invoice"
                                    />
                                    <input
                                        type="hidden"
                                        name="custom"
                                        :value="`RENEW:${order.long_id}`"
                                    />
                                    <input type="hidden" name="lc" value="EN" />
                                </div>
                                <button
                                    type="submit"
                                    class="btn btn-common btn-paypal"
                                >
                                    <span>Pay with</span>
                                    <img src="/paypal.png" alt="" />
                                </button>
                            </form>
                        </div>
                        <!-- <div class="mt-3 d-flex justify-content-center">
                            <div
                                ref="oftenpay"
                                class="oftenpay-button-container"
                            >
                                <button
                                    class="btn btn-common btn-oftenpay"
                                    @click="payWithOftenPay"
                                >
                                    <span>Pay with</span>
                                    <img src="/oftenpay.png" alt="" />
                                </button>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from "../../config";
import axios from "axios";
import moment from "moment";

export default {
    name: "Purchase",
    data() {
        return {
            lang: this.$store.state.language,

            yearsToExtend: 1,
            config: config,

            loading: false,
            paidSuccessfully: false,

            globalSettings: null,
            licenseKeys: [],

            next: false,
            order: null
        };
    },
    mounted: function() {
        this.licenseKeys = this.$route.params.selectedToRenew;
    },
    created: function() {
        this.getGlobalSettings();
    },
    computed: {
        invoice: function() {
            let r = Math.random()
                .toString(36)
                .slice(2, 7);
            return `${this.order.long_id}-${r}`;
        }
    },
    methods: {
        timeFormat(t) {
            return moment
                .utc(t)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
        },
        back() {
            this.$router.go(-1);
        },
        setLoaded() {
            this.loading = false;
            //   this.renderPayPalButton();
        },
        getGlobalSettings() {
            const self = this;

            self.loading = true;

            const url = `${config.server}/api/v1/global_settings`;

            axios
                .get(url)
                .then(function(response) {
                    self.globalSettings = response.data;
                })
                .catch(function(error) {
                    alert(error.response.data);
                })
                .finally(function() {
                    self.queried = true;
                    self.loading = false;
                });
        },
        createOrder() {
            const self = this;

            self.loading = true;

            const url = `${config.server}/api/v1/renew_orders`;

            axios
                .post(url, {
                    license_keys: self.licenseKeys,
                    validity: self.yearsToExtend * 12
                })
                .then(function(response) {
                    self.order = response.data;
                    self.next = true;
                })
                .catch(function(error) {
                    alert(error.response.data);
                })
                .finally(function() {
                    self.loading = false;
                });
        },
        payWithOftenPay() {
            const self = this;

            self.loading = true;

            const url = `${config.server}/api/v1/oftenpay/renew_orders/${self.order.id}`;

            axios
                .get(url)
                .then(function(response) {
                    window.location = response.data;
                })
                .catch(function(error) {
                    alert(error.response.data);
                })
                .finally(function() {
                    self.queried = true;
                    self.loading = false;
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.quantity {
    font-size: 24px;
    font-weight: 600;
}

.card-plan {
    min-height: 50px !important;
}

.form-pay {
    width: 100%;
}

.btn-paypal {
    color: #333;
    border-radius: 30px;
    width: 300px;
    background-color: #fec339;
    box-shadow: #cf9c23 1px 1px 1px;

    > span {
        font-size: 18px;
        font-weight: 500;
        padding-right: 10px;
        font-family: HelveticaNeue-CondensedBold;
    }

    > img {
        width: 120px;
    }
}

.btn-oftenpay {
    font-size: 1.1rem;
    width: 300px;
    color: #eee;
    border-radius: 30px;
    border: none;
    background: linear-gradient(172.83deg, #020664 1.44%, #c400bd 48.32%);
    box-shadow: #8c0387 1px 1px 1px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > span {
        font-size: 18px;
        font-weight: 500;
        padding-right: 10px;
        font-family: HelveticaNeue-CondensedBold;
    }

    > img {
        width: 120px;
    }
}

.field-title {
    font-size: 24px;
    font-weight: 600;
    font-family: HelveticaNeue-CondensedBlack;
}

.license-key {
    font-size: 14px;
    font-weight: 600;
    font-family: "Courier New", Courier, monospace;
}

.license-card {
    padding: 10px;
    border-radius: 5px;

    font-size: 12px;
    line-height: 13px;

    display: grid;
    grid-template-columns: 100px auto;
    gap: 0 5px;

    max-width: 350px;

    &.ios-basic {
        // border: 2px solid #144cdb;
        box-shadow: 0 0 2px 0px #144cdb;
        background-image: linear-gradient(to top, #5badf1, #2060fe);
        color: #ffffff;
    }

    &.ios-pro {
        // border: 2px solid #d6491a;
        box-shadow: 0 0 2px 0px #d6491a;
        background-image: linear-gradient(to top, #ff8a00, #da1b60);
        color: #ffffff;
    }

    &.macos-basic {
        // border: 2px solid #d6491a;
        box-shadow: 0 0 2px 0px #415d43;
        background-image: linear-gradient(to top, #b9e769, #0db39e);
        color: #ffffff;
    }

    &.macos-pro {
        // border: 2px solid #d6491a;
        box-shadow: 0 0 2px 0px #b5852c;
        background-image: linear-gradient(to top, #ffd100, #f7af29);
        color: #ffffff;
    }

    &.default {
        background-color: #eeeeee;
        border: 1px solid #e1e1e1;
        // box-shadow: 0 0 50px 0px #e1e1e1;
        color: #333333;
    }
}
</style>
