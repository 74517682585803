var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","no-wrap":"","z-index":"1000"},scopedSlots:_vm._u([(_vm.paidSuccessfully)?{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('i',{staticClass:"icon icon-check"}),_c('h3',[_vm._v("Thank you!")])])]},proxy:true}:null],null,true)}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 p-3"},[_c('button',{staticClass:"btn btn-common btn-back",on:{"click":_vm.back}},[_vm._v(" Back ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(" You are going to extend validity period of AutoTouch licenses: ")]),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('h6',{staticClass:"field-title"},[_vm._v("Licenses:")]),(!_vm.next)?_c('div',_vm._l((_vm.licenseKeys),function(item){return _c('div',{key:item,staticClass:"license-key"},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e(),(_vm.order)?_c('div',{staticClass:"d-flex flex-row align-content-start flex-wrap",staticStyle:{"gap":"10px"}},_vm._l((_vm.order.license_keys),function(item){return _c('div',{key:item.id,staticClass:"license-card",class:{
                                    'ios-pro': item.plan === 'IOS_PRO',
                                    'ios-basic': item.plan === 'IOS_BASIC',
                                    'macos-basic':
                                        item.plan === 'MACOS_BASIC',
                                    'macos-pro': item.plan === 'MACOS_PRO',
                                    default: ![
                                        'IOS_BASIC',
                                        'IOS_PRO',
                                        'MACOS_BASIC',
                                        'MACOS_PRO'
                                    ].includes(item.plan)
                                }},[_c('div',[_vm._v("License:")]),_c('div',{staticClass:"license-key"},[_vm._v(" "+_vm._s(item.license_key)+" ")]),_c('div',[_vm._v("Plan:")]),_c('div',[_vm._v(" "+_vm._s(item.plan)+" ")]),_c('div',[_vm._v("Old Validity:")]),_c('div',[_vm._v(" "+_vm._s(item.old_validity)+" Months ")]),_c('div',[_vm._v("New Validity:")]),_c('div',[_vm._v(" "+_vm._s(item.new_validity)+" Months ")]),_c('div',[_vm._v("Current expires:")]),_c('div',[_vm._v(" "+_vm._s(item.expire_time ? _vm.timeFormat(item.expire_time) : 'N/A')+" ")]),_c('div',[_vm._v("Extend to time:")]),_c('div',[_vm._v(" "+_vm._s(item.extend_to_time ? _vm.timeFormat(item.extend_to_time) : 'N/A')+" ")])])}),0):_vm._e()]),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('h6',{staticClass:"field-title"},[_vm._v("Period to Extend:")]),_c('div',[_c('b-form-spinbutton',{attrs:{"min":"1","max":"100","size":"sm","inline":"","disabled":_vm.next},model:{value:(_vm.yearsToExtend),callback:function ($$v) {_vm.yearsToExtend=$$v},expression:"yearsToExtend"}}),_c('label',{staticClass:"ml-2"},[_vm._v("Year(s)")])],1)])])]),(!_vm.next)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn btn-common",attrs:{"disabled":!_vm.licenseKeys || _vm.licenseKeys.length <= 0},on:{"click":_vm.createOrder}},[_vm._v(" Confirm ")])])]):_vm._e(),(_vm.order)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 py-2"},[_c('h6',{staticClass:"field-title"},[_vm._v("Total Amount:")]),_c('div',{staticClass:"font-weight-bold"},[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"30px"}},[_vm._v("$ "+_vm._s(_vm.order.amount / 100))]),_c('span',[_vm._v("USD")])])]),_c('div',{staticClass:"col-12 p-3"},[_c('div',{staticClass:"mt-3 d-flex justify-content-center w-100"},[(
                                _vm.globalSettings &&
                                    _vm.globalSettings.paypal_payee_email_1
                            )?_c('form',{attrs:{"id":"form_pay","name":"form_pay","action":_vm.config.paypal.pdt,"method":"post"}},[_c('div',{staticStyle:{"display":"none"}},[_c('input',{attrs:{"type":"hidden","name":"cmd","value":"_xclick"}}),_c('input',{attrs:{"type":"hidden","name":"business"},domProps:{"value":_vm.globalSettings.paypal_payee_email_1}}),_c('input',{attrs:{"type":"hidden","name":"item_name"},domProps:{"value":"AutoTouch licenses period of validity renew."}}),_c('input',{attrs:{"type":"hidden","name":"item_number"},domProps:{"value":1}}),_c('input',{attrs:{"type":"hidden","name":"amount"},domProps:{"value":_vm.order.amount / 100}}),_c('input',{attrs:{"type":"hidden","name":"currency_code","value":"USD"}}),_c('input',{attrs:{"type":"hidden","name":"return"},domProps:{"value":_vm.config.paypal.returnUrl}}),_c('input',{attrs:{"type":"hidden","name":"notify_url"},domProps:{"value":_vm.config.paypal.notifyUrl}}),_c('input',{attrs:{"type":"hidden","name":"invoice"},domProps:{"value":_vm.invoice}}),_c('input',{attrs:{"type":"hidden","name":"custom"},domProps:{"value":("RENEW:" + (_vm.order.long_id))}}),_c('input',{attrs:{"type":"hidden","name":"lc","value":"EN"}})]),_vm._m(0)]):_vm._e()])])]):_vm._e()])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn btn-common btn-paypal",attrs:{"type":"submit"}},[_c('span',[_vm._v("Pay with")]),_c('img',{attrs:{"src":"/paypal.png","alt":""}})])}]

export { render, staticRenderFns }